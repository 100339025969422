.admin-homepage {
  h1 {
    font-size: 20px;
    line-height: 25px;
    margin: 35px 0;
  }

  .homepage-list {
    display: flex;
    flex-direction: row;

    .homepage-action {
      border: 1px solid $color-fe;
      border-radius: 4px;
      background-color: $color-white;
      height: 332px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 15px 0 15px;
      flex: 1;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .action-container {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;

        .title {
          font-family: $font-primary;
          text-align: center;
          font-size: 20px;
          margin-top: 20px;
        }
      }
    }
  }
}
