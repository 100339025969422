.audit-container {
  .check-parent {
    margin: 25px 0 10px 0;
  }
  .form-group {
    display: flex;
    flex-direction: row;

    .inputs-wrapper {
      select,
      input {
        color: $color-19;
        font-size: 15px;
        border: 1px solid $color-fe;
        border-radius: 4px;
        padding: 9px 15px;
        width: 100%;
        line-height: 20px;

        &:focus {
          outline: none;
        }
      }
    }
    label {
      color: $color-ac;
      font-size: 14px;
      line-height: 28px;
    }
  }
}

.audit__content.admin-lists__content {
  .sortingColumn {
    cursor: pointer;
  }
  .list__titles {
    .field:nth-of-type(1),
    .field:nth-of-type(2) {
      .label {
        cursor: pointer;
      }
    }
  }
}
