.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h1, span {
    font-family: $font-primary;
  }

  h1 {
    font-size: 100px;
    line-height: 60px;
  }

  span {
    font-size: 16px;
    text-align: center;
  }

  span.subtitle {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 55px;
  }

  button {
    margin-top: 55px;
    margin-bottom: 20px;
  }

}
