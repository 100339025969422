.custom-tooltip{
    background-color: #fff;
    padding: 10px;
    &.income {
      border: 1px solid $color-income;
    }
    &.expense {
      border: 1px solid $color-expense;
    }

}
