@import 'styles/variables.scss';
.__react_component_tooltip {
  position: fixed !important;
  &.type-dark,&.type-info {
    min-width:500px;
    max-width:600px;
    background-color: $color-ac!important;
    &.place-bottom {
      &:after {
        border-bottom-color: $color-ac;
      }
    }
    &.place-top {
      &:after {
        border-top-color: $color-ac;
      }
    }
    &.place-right {
      &:after {
        border-right-color: $color-ac;
      }
    }
    &.place-left {
      &:after {
        border-left-color: $color-ac;
      }
    }
  }

 

  span {
    color: $color-white;
    font-size: 14px!important;
  }
  a {
    color: $color-white;
    opacity:0.8;
    margin-left:0!important;
  }
  &.type-info {
    min-width:auto;
    max-width:600px;
  }
}
