// Landing pages layout
body {
  background: $color-white;
  overflow-x: hidden;
}

.landing-pages-container {
  height: 100%;
  .page-container {
    width: 100%;
    overflow: hidden;
  }

  .header-container {
    padding-right: 20px;
    padding-left: 20px;

    > div {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .col-full {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
}
