.footer-container {
  margin: 123px 0 123px 0;
  padding-top: 123px;
  padding-bottom: 123px;
  .footer--app{
    padding: 0;
    margin: 0;
  }
  .footer-bottombar {
    width: calc(100vw - 80px);
    margin: 35px auto;
    display: flex;
    justify-content: space-between;

    span {
      color: $color-77;
      font-size: 14px;
    }
  }
  .footer-standard-text {
    color: #ffffff;
    font-family: $hp-primary-font;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
}
