.RichTextEditor__root___2QXK-{
  font-family: $font-primary;
  .public-DraftEditor-content {
    height: 300px;
    &> div {
      overflow: scroll;
    }
    div {
      line-height: 20px;
    }
  }
  select {
    font-size: 14px !important;
    padding: 4px 10px !important;
  }
}
