.donut-chart__chart {
  position: relative;
  .donut-chart_year {
    position: absolute;
    top: calc(50% - 12px);
    width: 100%;
    left: 6px;
    text-align: center;
    font-family: $font-primary;
    font-size: 34px;
    font-weight: bold;
    line-height: 42px;
  }
  .recharts-layer {
    cursor: pointer;
  }
}
