$laptop-large: 1600px;
$laptop-small: 1024px;
$desktop: 991px;
$tablet: 767px;
$handheld: 568px;

/* colors */
$color-gray: #333;

/* typography */
$font-size-base-unit: 1.6;
$line-height-base: 1.5;
$text-color: #1A1919;


// Typography
$font-primary: SourceSansPro;
$font-size-base: 14px;
$font-size-big: 18px;
$font-size-small: 12px;

// Colors
$color-header-grey:#3C404B;
$color-white: #fff;
$color-light-grey: #E8E8E8;
$color-green: #4BB694;
$disabled-input: #dddddd;

$color-primary: #37a995;
$color-gray-fc: #f7f8fc;
$color-fe: #E4EAFE;
$color-e8: #E8E8E8;
$color-94: #4BB694;
$color-red: #E88389;
$color-77: #777777;
$color-3d: #33363D;
$color-19: #1A1919;
$color-ac: #808BAC;
$color-b4: #1A96B4;
$color-a19: #191A19;
$color-7f: #7F7F7F;
$color-de: #bbc4de;
$color-fb: #F7F8FB;
$color-de: #dedede;
$color-83: #E8E683;
$color-89: #E88389;
$color-f9: #F9F9F9;
$color-4b: #3C404B;
$color-b1: #A7B5B1;
$color-admin: #FBFCFE;
$color-21: #212422;

$color-income: #4BB694;
$color-expense: #E88389;
$color-disable: #E8E683;

$disabled-field-color: #DDDDDD;

$screen-lg-min: 1400px;

$color-red-error:  #E88389;
$color-yellow-warn: #E8E683;
$color-green-success: #4BB694;
$color-blue-info: #1A96B4;

$dot-default-color: #bbb;
$dot-processing-color: #CCE5FF;
$dot-failed-color: #e88289;
$dot-finish-color: #4bb694;
$dot-warning-color: #FDF3CD;
$dot-success-color: #10a54a;

// Home-Page
$hp-heading-color: #061a06;
$hp-text-color: #191a19;
$hp-primary-font: Roboto Slab;
$hp-font: SourceSansPro;
$hp-separator-color: #a6aec4;
$hp-background-sections: #F9FAFD;
$hp-white: #ffffff;
$hp-container-padding: 10%;

$footer-background: #3C404C;

// Small tablets and large smartphones (portrait view)
$screen-xs-min: 375px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 767px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;