@import '../../../styles/variables.scss';

.react-date-picker {
  &.react-date-picker {
    position: relative;
    display: block;
  }
  &__inputGroup {
    padding: 9px 5px !important;
    border: 1px solid #e4eafe;
    border-radius: 4px;

    .error & {
      border: 1px solid $color-89;
    }

    &__divider {
      max-width: 8px;
    }

    &__input--hasLeadingZero {
      margin-left: 0 !important;
    }

    input {
      &.react-date-picker__inputGroup__input {
        &:invalid {
          background: transparent;
        }
      }
    }
    
    &__input {
      height: auto !important;
      padding: 0 !important;
      border: none !important;
      width: auto !important;
      text-align: center;
    }
  }

  .react-date-picker__wrapper {
    border: none;
  }

  &__calendar {
    top: 100% !important;
    bottom: auto !important;
    .react-calendar {
      width: 280px;
      max-width: 100vw;
      box-sizing: border-box;
      padding: 0 0px 12px;
      z-index: 5;
      border: 1px solid $color-fe;
      border-radius: 4px;
      background-color: $color-white;
      box-shadow: 0 0 10px 0 rgba(128, 139, 172, 0.1);

      &:before {
        content: '';
        display: inline-block;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid rgba(128, 139, 172, 0.1);
        border-top: 0;
        position: absolute;
        top: -10px;
        left: 10px;
      }
      &:after {
        content: '';
        display: inline-block;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 8px solid $color-white;
        border-top: 0;
        position: absolute;
        top: -8px;
        left: 12px;
      }
      &.datepicker-bottom {
        &:before {
          bottom: -10px;
          left: 10px;
          transform: rotate(180deg);
          top: auto;
        }
        &:after {
          bottom: -8px;
          left: 12px;
          transform: rotate(180deg);
          top: auto;
        }
      }
      button {
        background-color: rgba(250, 0, 0, 0) !important;
        outline-color: transparent;
        outline: 0;
        width: auto !important;
      }
      .react-calendar__navigation {
        margin-bottom: 12px;
        padding: 0 12px;
        border-bottom: 1px solid $color-fe;
        .react-calendar__navigation__label {
          width: 34px;
          color: $color-a19;
          font-family: $font-primary;
          font-size: 16px;
          line-height: 20px;
          border-radius: 3px;
          text-align: center;
          display: inline-block;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: $color-b4 !important;
            color: $color-white;
          }
        }
        .react-calendar__navigation__arrow {
          box-sizing: border-box;
          font-size: 24px;
          color: $color-a19;
          font-family: $font-primary;
          position: relative;
          top: -2px;
          &.react-calendar__navigation__prev2-button,
          &.react-calendar__navigation__next2-button {
            display: none;
          }
          &:hover {
            transform: scale(0.8);
          }
        }
      }
      .react-calendar__month-view,
      .react-calendar__year-view,
      .react-calendar__decade-view,
      .react-calendar__century-view {
        padding: 0 12px;
        .react-calendar__tile {
          font-size: 15px;
          line-height: 20px;
          padding: 5px 10px;
          color: $color-ac;
          font-family: $font-primary;
          border-radius: 4px;
          margin: 2px 0 !important;
          &--active,
          &:hover {
            background-color: $color-b4 !important;
            color: $color-white;
          }
        }
      }
      .react-calendar__month-view__days__day,
      .react-calendar__year-view__months__month,
      .react-calendar__decade-view__years__year,
      .react-calendar__century-view__decades__decade {
        &:disabled {
          color: $color-light-grey;
        }
      }
      .react-calendar__year-view,
      .react-calendar__decade-view {
        button.react-calendar__tile {
          flex-basis: 25% !important;
          max-width: 25% !important;
          overflow: hidden;
          padding: 10px 10px;
          margin: 5px 0 !important;
        }
      }
      .react-calendar__century-view {
        button.react-calendar__tile {
          flex-basis: 25% !important;
          max-width: 25% !important;
        }
      }
    }
  }
}
