@keyframes modal-anim {
    0% {
        top: 0;
        opacity: 0
    }
    100% {
        top: 100px;
        opacity: 1
    }
}

.ReactModalPortal {
    .Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.9);
        z-index:3;
        .Modal {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            animation: modal-anim 0.3s ease-out;
            top: 100px;
            background-color: white;
            padding: 30px;
            box-sizing: border-box;
            min-height: calc(100vh - 190px);
            border: 1px solid $color-fe;
            max-width: 667px;
            border-radius: 5px;
            h2 {
                font-size: 18px;
                line-height: 22px;
                font-family: $font-primary;
            }
            p {
                font-size: 16px;
                line-height: 20px;
                font-family: $font-primary;
                &+h4 {
                    border-top: 1px solid $color-de;
                    margin-top: 30px;
                }
            }
            li {
                font-size: 16px;
                line-height: 20px;
                font-style: italic;
                font-family: $font-primary;
            }
            h4 {
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                padding: 30px 0 20px;
                font-family: $font-primary;
            }
            .close-modal {
                position: absolute;
                right: 15px;
                top: 15px;
                width: 15px;
                height: 15px;
                display: block;
                cursor: pointer;
                transition: 0.3s ease-out;
                transform:translateZ(0);
                &:hover {
                    transform: scale(0.8) translateZ(0);
                    transform-origin:center;
                }
            }
        }
    }
}
