#app > div {
  width: 100%;
}
#app > div .overlay-wrapper {
  height: 0;
  padding: 0
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99998;
  text-align: center;
  background-color: white;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 0.8;
  display: flex;
  align-items: center;
}


.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
  z-index: 99999;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #00b5ca;

  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
