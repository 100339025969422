.file__upload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  >div:not(.check-upload-both) {
    flex: 0 0 calc(50% - 15px);
    display: flex;
    flex-direction: column;
    background-color: #F7F8FB;
    border: 1px solid #E4EAFE;
    border-radius: 3px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.03);
    align-items: center;
    justify-content: center;
    height: 225px;
    position: relative;
    &.upload-one {
      flex: 0 0 100%!important;
      +div {
        display: none!important;
      }
    }
    .has-file-checkmark {
      position: absolute;
      top: 15px;
      right: 15px;
      position: absolute;
      height: 25px;
      width: 25px;
      background: $color-b4;
      border-radius: 100%;
      margin-left: 0;
      text-align: center;
      display: none;
      svg {
        width: 12px;
        height: 10px;
      }
    }
    .file-drop {
      width: 100%;
      height: 100%;
      .file-drop-target {
        height: 100%;
        .top,
        .bottom {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 50%;
          margin: 0 auto;
        }
        .top {
          border-bottom: 1px solid #E4EAFE;
          padding-bottom: 15px;
          justify-content: flex-end;
          span {
            display: block;
            color: #191A19;
            &:first-child {
              margin-top: 15px;
              font-size: 18px;
              text-transform: uppercase;
              margin-left: 0;
            }
            &:last-child {
              margin-left: 0;
              font-size: 14px;
            }
          }
        }
        .bottom {
          padding-top: 15px;
          span {
            color: #808BAC;
            font-size: 16px;
            margin-bottom: 15px;
          }
          .upload-btn-wrapper {
            button {
              background: $color-b4;
              padding: 4px 30px;
              justify-content: flex-start;
              transition: 0.3s ease-out;
            }
            input[type=file] {
              cursor: pointer;
            }
             ::-webkit-file-upload-button {
              cursor: pointer;
            }
            &:hover {
              button {
                opacity: 0.8
              }
            }
            &.hasFile {
              button {
                background: $color-3d
              }
            }
          }
        }
      }
    }
    &.file-uploaded {
			.has-file-checkmark {
				display: flex!important;
				align-items: center;
				justify-content: center;
			}
			.file-drop {
				.file-drop-target {
					.bottom {
						.upload-btn-wrapper {
							button {
								background: $color-3d!important
							}
						}
					}
				}
			}
		}
  }
  .check-upload-both {
    display: none;
    flex: 0 0 100%;
    margin-top: 15px;
    margin-left: 2px;
    &.show-check {
      display: flex!important;
    }
  }
}

.steps__topbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span.topbar__title {
    font-size: 17px;
  }
  .info {
    margin-top: 5px;
    margin-left: 10px;
  }
}

.subtitle {
  font-size: 14px;
  font-style: italic;
  color: $color-ac;
  margin-bottom: 20px;
  display: block;
}
