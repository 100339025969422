#step1-form {
  label.partner-label {
    margin-bottom: 0px;
  }
  span {
    font-family: $font-primary;
  }
  .details-wrapper {
    padding: 30px;
    border-bottom: 1px solid $color-fe;
    .subtitle {
      font-size: 14px;
      font-style: italic;
      color: $color-ac;
      margin-bottom: 20px;
      display: block;
    }
    .steps__radio {
      margin-bottom: 5px;
      span.custom-radio-label {
        color: #7f7f7f;
        font-size: 16px;
        margin-left: 10px;
      }
    }
    input[type='radio'] {
      display: none;
      & + label {
        display: flex;
        cursor: pointer;
        .custom-radio {
          height: 24px;
          width: 24px;
          border: 1px solid $color-e8;
          border-radius: 50px;
          cursor: pointer;
          margin-bottom: 0;
          display: inline-block;
          position: relative;
          margin-left: 0;
        }
      }
      &:checked + label .custom-radio {
        &:before {
          content: '';
          height: 14px;
          width: 14px;
          border-radius: 50px;
          background-color: $color-b4;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    span.title {
      font-size: 17px;
      margin-bottom: 20px;
      display: block;
    }
    .form-group {
      display: flex;
      flex-direction: row;
      .form-group {
        margin-bottom: 0;
      }
      .inputs-wrapper {
        select,
        input {
          color: $color-19;
          font-size: 15px;
          border: 1px solid $color-fe;
          border-radius: 4px;
          padding: 9px 15px;
          width: 100%;
          line-height: 20px;
          &:focus {
            outline: none;
          }
        }
        &:not(:last-of-type) {
          margin-right: 20px;
        }
        &[class*='childrenData'] {
          &:not(:last-of-type) {
            margin-right: 16px;
          }
          .react-date-picker__wrapper {
            input.react-date-picker__inputGroup__input {
              width: 24px !important;
              &.react-date-picker__inputGroup__year {
                width: 30px !important;
              }
            }
          }
        }

        &[class*='personalBirthdate'] {
          .react-date-picker__wrapper {
            input.react-date-picker__inputGroup__input {
              width: 40px !important;
            }
          }
        }
      }
      .info {
        align-self: center;
        margin-right: 20px;
      }
      .info-icon {
        padding-left: 5px;
      }
      .arrow {
        align-self: center;
        margin-right: 20px;
      }
      label {
        color: $color-ac;
        font-size: 14px;
        line-height: 28px;
        .datepicker__calendar {
          line-height: 18px;
        }
      }
      .placeholder {
        padding: 10px;
      }
    }
    button {
      margin: 0;
    }
    .steps__topbar {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      span.topbar__title {
        font-size: 17px;
      }
      .info {
        margin-top: 5px;
        margin-left: 10px;
      }
    }
    .file__upload {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;
      .disabled {
        opacity: 0.3;
        pointer-events: none;
      }
      > div:not(:last-child) {
        flex: 0 0 calc(50% - 15px);
        display: flex;
        flex-direction: column;
        background-color: $color-fb;
        border: 1px solid $color-fe;
        border-radius: 3px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.03);
        align-items: center;
        justify-content: center;
        height: 225px;
        position: relative;
        .file-drop {
          width: 100%;
          height: 100%;
          .file-drop-target {
            height: 100%;
            .top,
            .bottom {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 50%;
              margin: 0 auto;
            }
            .top {
              border-bottom: 1px solid $color-fe;
              padding-bottom: 15px;
              justify-content: flex-end;
              span {
                display: block;
                color: $color-a19;
                &:first-child {
                  margin-top: 15px;
                  font-size: 18px;
                  text-transform: uppercase;
                  margin-left: 0;
                }
                &:last-child {
                  margin-left: 0;
                  font-size: 14px;
                }
              }
            }
            .bottom {
              padding-top: 15px;
              span {
                color: $color-ac;
                font-size: 16px;
                margin-bottom: 15px;
              }
              .upload-btn-wrapper {
                button {
                  background: $color-b4;
                  padding: 4px 30px;
                  justify-content: flex-start;
                  transition: 0.3s ease-out;
                }
                input[type='file'] {
                  cursor: pointer;
                }
                ::-webkit-file-upload-button {
                  cursor: pointer;
                }
                &:hover {
                  button {
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ReactModalPortal {
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 3;
    .Modal {
      min-width: 1200px;
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      animation: modal-anim 0.3s ease-out;
      top: 100px;
      background-color: white;
      padding: 30px;
      box-sizing: border-box;
      min-height: calc(100vh - 390px);
      border: 1px solid $color-fe;
      max-width: 667px;
      border-radius: 5px;
    }
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  .btn {
    cursor: pointer;
  }
  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.household-tooltip-container {
  margin-top: 35px;
  margin-left: 0px;
}

@-moz-document url-prefix() {
  .react-date-picker__inputGroup__month {
    width: 40px !important;
  }
  .react-date-picker__inputGroup__day {
    width: 40px !important;
  }
  .react-date-picker__inputGroup__year {
    width: 60px !important;
  }
}

.save-data--disclaimer {
  color: $color-77;
  font-size: 13px;
  font-style: italic;
  line-height: 20px;
}

h1.save__data__title {
  color: $color-21;
  font-size: 17px;
  line-height: 22px;
}
