.retirement-plans {
  label:nth-of-type(1) {
    padding-top: 10px;
  }
  input[type='radio'] {
    display: none;
    & + label {
      display: flex;
      cursor: pointer;
      .custom-radio {
        height: 24px;
        width: 24px;
        border: 1px solid $color-e8;
        border-radius: 50px;
        cursor: pointer;
        margin-bottom: 0;
        display: inline-block;
        position: relative;
        margin-left: 0;
      }
    }
    &:checked + label .custom-radio {
      &:before {
        content: '';
        height: 14px;
        width: 14px;
        border-radius: 50px;
        background-color: $color-b4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .allign-tooltip {
    div[id^='connect-tooltip-'] {
      position: absolute !important;
    }
  }

  span {
    margin-left: 10px;
    font-size: $font-size-base;
    color: $color-19;
    line-height: 24px;
  }
  .retirement-plans-selection {
    padding: 15px 15px 15px 0;
    display: flex;
    align-items: center;
  }

  a {
    margin-left: 10px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .__react_component_tooltip {
    span {
      color: $color-white;
    }
  }
}
