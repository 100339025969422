.customer-journey-container {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  align-items: flex-start;

  .welcome-container,
  .steps-container {
    border: 1px solid $color-fe;
    background-color: $color-white;
    border-radius: 3px;
  }

  .welcome-container {
    flex: 0 0 23%;
    margin-right: 40px;
    padding: 30px;

    span {
      display: block;
      font-family: $font-primary;
      color: $color-ac;
      font-size: 16px;
      margin-bottom: 30px;
    }

    span.title {
      font-size: 20px;
      color: $color-a19;
      margin-bottom: 0;
    }
  }

  .steps-container {
    flex: 1;
  }
}
