.profile-container {
  h1 {
    font-size: 20px;
    line-height: 25px;
    margin: 35px 0;
  }

  .profile-wrapper {
    display: flex;
  }
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  margin-left: -13px;
  align-items: center;

  input {
    visibility: hidden;
  }
  .checkbox-label {
    height: 20px;
    width: 20px;
    border: 1px solid $color-e8;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 0;
  }

  .checkbox-label-checked {
    height: 20px;
    width: 20px;
    border: 1px solid $color-b4;
    border-radius: 4px;
    background-color: $color-b4;
    cursor: pointer;
  }

  span {
    font-family: $font-primary;
    font-size: 16px;
    color: $color-7f;
    display: inline-flex;
    margin-left: 8px;
  }
}
