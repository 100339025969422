.xs {
  @media only screen and (min-width: $handheld) {
    flex: 0 0 6%;
    max-width: 6%;
  }
}
.s {
  @media only screen and (min-width: $handheld) {
    flex: 0 0 15%;
    max-width: 15%;
  }
}

.m {
  @media only screen and (min-width: $handheld) {
    flex: 0 0 17%;
    max-width: 17%;
  }
}

.l {
  @media only screen and (min-width: $handheld) {
    flex: 0 0 31%;
    max-width: 31%;
  }
}


#step2-form {
  padding-bottom:0;
  span, div {
    font-family: $font-primary;
  }

  .xs {
    @media only screen and (min-width: $handheld) {
      flex: 0 0 6%;
      max-width: 6%;
    }
  }
  .s {
    @media only screen and (min-width: $handheld) {
      flex: 0 0 15%;
      max-width: 15%;
    }
  }

  .m {
    @media only screen and (min-width: $handheld) {
      flex: 0 0 17%;
      max-width: 17%;
    }
  }

  .l {
    @media only screen and (min-width: $handheld) {
      flex: 0 0 31%;
      max-width: 31%;
    }
  }

  .details-wrapper {
    padding: 30px;

    .steps__radio {
      margin-bottom: 5px;
      .radio-option {
        .radio-button {
          span {
            color: #7f7f7f;
            font-size: 16px;
            margin-left: 10px !important;
            margin-top: 0 !important;
          }
        }
      }
    }
    input[type='radio'] {
      display: none;
      & + label {
        display: flex;
        cursor: pointer;
        .custom-radio {
          height: 24px;
          width: 24px;
          border: 1px solid #e8e8e8;
          border-radius: 50px;
          cursor: pointer;
          margin-bottom: 0;
          display: inline-block;
          position: relative;
          margin-left: 0;
        }
      }
      &:checked + label .custom-radio {
        &:before {
          content: '';
          height: 14px;
          width: 14px;
          border-radius: 50px;
          background-color: #1a96b4;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    //savings
    .nibud-selection {
      display: inline-flex;
      .radio-option {
        min-width: 300px;
        label span:last-of-type {
          margin-top: 2px;
          margin-left: 10px;
        }
      }
    }

    .steps__topbar {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      span.topbar__title {
        font-size: 17px;
      }
      .info {
        margin-top: 5px;
        margin-left: 10px;
      }
    }

    span.title {
      font-size: 17px;
      display: block;
    }

    &.details__topbar {
      display: flex;
      padding-bottom: 20px;

      div {
        font-size: 14px;
        color: $color-ac;

        span {
          font-size: 14px;
          color: $color-94;
          font-weight: 600;
          text-decoration: underline;
          display: inline-block;
          margin-left: 15px;
          cursor: pointer;
        }

        &.center {
          text-align: center;
        }
      }
    }

    &.details__rows {
      padding-top: 0;

      .row-wrapper {
        border: 1px solid $color-fe;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .row-base,
        .row-details {
          display: flex;
          width: 100%;
          align-items: center;
          height: 82px;

          .center {
            text-align: center;
            position: relative;
            .currency {
              position: absolute;
              height: 100%;
              left: 30px;
              top: 0;
            }
          }

          span {
            font-size: 14px;
          }

          .form-group {
            margin: 0;
            label {
              margin: 0;
              display: inline;
              width: 100%;
            }
            input {
              color: $color-19;
              font-size: 15px;
              border: 1px solid $color-fe;
              border-radius: 4px;
              padding: 9px 15px;
              width: 100%;
              line-height: 20px;
              text-align: right;

              &:focus {
                outline: none;
              }
            }
            &.right {
              margin-left: auto;
            }
          }

          > span:first-of-type {
            font-weight: 600;
            padding-left: 30px;
          }
          > span:last-of-type {
            padding-right: 30px;
            text-align: right;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 32px;

            svg {
              g {
                fill: $color-de;
              }
              line {
                stroke: $color-de;
              }
            }
          }

          .nibud {
            text-transform: uppercase;
            font-size: 13px;
            line-height: 32px;

            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 160px;
              // height: 31px;
              margin: 0 auto;
              cursor: pointer;

              &.active {
                border-radius: 4px;
                background-color: $color-94;
                // height: 30px;
                color: $color-white;
              }

              &.disabled {
                border: 1px solid $color-fe;
                border-radius: 4px;
                background-color: $color-white;
                svg {
                  height: 10px;
                  width: 6px;
                  margin-left: 8px;
                }
              }
              svg {
                margin-left: 10px;
              }
            }
          }
        }

        &.open {
          flex-direction: column;
          background-color: $color-fb;

          .row-details {
            .name span {
              padding-left: 30px;
            }
          }
        }
      }
    }

    &.details__bottombar {
      padding: 30px;
      border-top: 1px solid $color-fe;
      span {
        font-size: 16px;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .nibud-items {
    width: 100%;
  }
}
