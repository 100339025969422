.user-profile-container {
  .form-group {
    display: flex;
    flex-direction: row;
    .form-group {
      margin-bottom: 0;
    }
    .inputs-wrapper {
      select,
      input {
        color: $color-19;
        font-size: 15px;
        border: 1px solid $color-fe;
        border-radius: 4px;
        padding: 9px 15px;
        width: 100%;
        line-height: 20px;
        &:focus {
          outline: none;
        }
      }
      &:not(:last-of-type) {
        margin-right: 20px;
      }
      &[class*='childrenData'] {
        &:not(:last-of-type) {
          margin-right: 16px;
        }
        .react-date-picker__wrapper {
          input.react-date-picker__inputGroup__input {
            width: 24px !important;
            &.react-date-picker__inputGroup__year {
              width: 30px !important;
            }
          }
        }
      }

      &[class*='personalBirthdate'] {
        .react-date-picker__wrapper {
          input.react-date-picker__inputGroup__input {
            width: 40px !important;
          }
        }
      }
    }
    .info {
      align-self: center;
      margin-right: 20px;
    }
    .info-icon {
      padding-left: 5px;
    }
    .arrow {
      align-self: center;
      margin-right: 20px;
    }
    label {
      color: $color-ac;
      font-size: 14px;
      line-height: 28px;
      .datepicker__calendar {
        line-height: 18px;
      }
    }
    .placeholder {
      padding: 10px;
    }
  }
}
