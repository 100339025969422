.card-wrapper {
	position: relative;
	height: calc(100% - 40px);
	padding-bottom: 100px;
}

.card-content__wrapper {
	margin-top: 30px;
	padding: 30px;
	background: $color-white;
	border: 1px solid $color-fe;
  border-radius: 4px;

	.card-container__titles,
	.card-container__line {
		display: flex;
		align-items: center;

	} // COMMON
	.card-container__titles {
		align-items: flex-start;
		.field {
			display: flex;
			align-items: center;
			&:not(:last-of-type) {
				margin-right: 20px;
			}
			&.left {
				justify-content: flex-start;
			}
			&.right {
				justify-content: flex-end;
			}
			&.center {
				justify-content: center;
			}
		}
	}
	.card-container__line {
		margin-top: 20px;
			&.extra-margin {
				margin-top: 50px;
			}
			.align--label--left{
				position: absolute;
				margin-top: -3%;
				margin-left: 3.5%;
			}
			.align--label--right{
				position: absolute;
				margin-top: -3%;
				margin-left: 13%;
			}
		.inputs-wrapper {
			&:not(:last-of-type) {
				margin-right: 20px;
			}
			label {
				width: 100%;
				margin-bottom: 0;
			}
			select,
			input {
				color: $color-19;
				font-size: 15px;
				border: 1px solid $color-fe;
				border-radius: 4px;
				padding: 9px 15px;
				width: 100%;
				line-height: 20px;
				&:focus {
					outline: none;
					border-color: $color-b4
				}
				&.error {
					border: 1px solid $color-red;
				}
			}
			select {
				line-height: 20px;
				font-size: 14px;
			}
			span {
				width: 100%;
			}
		}
		.field {
			border: 1px solid $color-fe;
			border-radius: 4px;
			padding: 7px 15px;
			display: flex;
			align-items: center;
			min-height: 38px;
			&.left {
				justify-content: flex-start;
			}
			&.right {
				justify-content: flex-end;
			}
			&.center {
				justify-content: center;
			}
			span {
				color: $color-19;
				font-size: 15px;
			}
			&:not(:last-of-type) {
				margin-right: 20px;
			}
			&.disabled {
				background: $disabled-field-color;
			}
		}
		.e-field {
			border: none;
			padding: 7px 0;
			.label {
				font-size: 14px;
			}
		}
		button {
			width: 100%;
			background: $color-b4;
		}
	}
	.field,
	.inputs-wrapper {
		&.xxs {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 2%;
				max-width: 2%;
			}
		}
		&.xs {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 5%;
				max-width: 5%;
			}
		}
		&.s {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 6%;
				max-width: 6%;
			}
		}
		&.ss {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 7%;
				max-width: 7%;
			}
		}
		&.m {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 8%;
				max-width: 8%;
			}
		}
		&.l {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 9%;
				max-width: 9%;
			}
		}
		&.xl {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 13%;
				max-width: 13%;
			}
		}
		&.xxl {
			@media only screen and (min-width: $handheld) {
				flex: 0 0 18%;
				max-width: 18%;
			}
		}
	}
	.label {
		color: $color-ac;
		font-size: 14px;
  } // Pension
  .btn.prepension-client {
    margin-left:15px;
	}
	.card-container__bottom {
		display: flex;
		align-content: flex-start;
	}
	.add-new {
		display: flex;
		align-items: center;
		margin-top: 30px;
		cursor: pointer;
		.label {
			text-transform: uppercase;
			font-size: 14px;
			color: $color-b4;
		}
		svg {
			margin-right: 10px;
		}
		&.disabled {
			opacity: 0.5;
		}
	}
	.card-container__line {
		.action-delete {
			border: 1px solid $color-fe;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			margin-right: 20px;
			cursor: pointer;
			padding: 0 5px;
		}
		.check-enddate {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.checkbox {
				width: 15%;
				margin-right: 10px;
			}
			.select-enddate {
				width: calc(85% - 10px)
			}
		}
		.pension-enddate-check {
			max-width: 2% !important;
			margin-right: 10px !important;
			.checkbox {
				width: 100%;
			}
		}
		.prepension-client,
		.prepension-partner {
			margin: 0 5px 0 5px;
		}
		.prepension-item {
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				display: none;
				g {
					fill: $color-b4;
				}
			}
			&.prepension-true {
				position: relative;
				svg {
					display: block;
				}
				&:after {
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					width: 25px;
					height: 25px;
					border: 1px solid $color-b4;
					border-radius: 100%;
					content: "";
				}
			}
		}
	}
	input[type="radio"] {
		display: none;
		&+label {
			display: flex;
			cursor: pointer;
			.custom-radio {
				height: 24px;
				width: 24px;
				border: 1px solid $color-e8;
				border-radius: 50px;
				cursor: pointer;
				margin-bottom: 0;
				display: inline-block;
				position: relative;
				margin-left: 0;
			}
		}
		&:checked+label .custom-radio {
			&:before {
				content: "";
				height: 14px;
				width: 14px;
				border-radius: 50px;
				background-color: $color-b4;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
	//savings
	.savings-distribution {
		display: inline-flex;
		.radio-option {
			min-width: 200px;
			label span:last-of-type {
				margin-top: 2px;
				margin-left: 10px;
			}
		}
	}
	.savings-distribution-table,
	.investments-distribution-table {
		margin-top: 20px;
	}
}

// EXPENSE CARD - HOUSING Costs
.card-content__wrapper.category-housing-costs {
	background: transparent;
	padding: 0;
	border: none;
	.nav-tabs {
		border-bottom: none;
		li {
			cursor: pointer;
			&:first-of-type {
				a {
					border-radius: 4px 0 0 0;
					border: 1px solid $color-fe;
					margin-right: -1px;
				}
			}
			&:nth-of-type(2) {
				a {
					border: 1px solid $color-fe;
				}
			}
			&:nth-of-type(3) {
				a {
					border-radius: 0 4px 0 0;
					border: 1px solid $color-fe;
					margin-left: -1px;
				}
			}
			a {
				font-size: 14px;
				font-family: $font-primary;
				color: #808BAC;
				padding: 11px 30px;
				&.active {
					color: #191A19;
					border-bottom: 1px solid $color-white;
				}
			}
		}
	}
	.nav-item{
		cursor: pointer;
	}
	form {
		margin-top: -30px;
	}
}

// INHERITANCE CARD
.card-content__wrapper.category-inheritance-slider {
	margin-top: -1px;
}

// SLIDER
.rc-slider {
	height: 100%;
	display: flex;
	align-items: center;
	.rc-slider-track {
		display: none;
	}
	.rc-slider-rail {
		height: 20px;
		border: 1px solid $color-fe;
		border-radius: 12px;
		background-color: $color-white;
	}
	.rc-slider-handle {
		height: 30px;
		width: 30px;
		border-radius: 100px;
		background-color: $color-b4;
		margin-top: 0;
    border: none;
    margin-left:-15px;
    &:active,
    &:focus {
      box-shadow:none;
    }
	}
}

// MORTGAGE MODAL
.ReactModalPortal {
	form {
		padding-bottom: 100px;
		.card-header__wrapper {
			margin-top: 0;
		}
		.card-content__wrapper {
			.field,
			.inputs-wrapper {
				&.xxs {
					@media only screen and (min-width: $handheld) {
						flex: 0 0 3.5%;
						max-width: 3.5%;
					}
				}
				&.m {
					@media only screen and (min-width: $handheld) {
						flex: 0 0 12%;
						max-width: 12%;
					}
				}
				&.l {
					@media only screen and (min-width: $handheld) {
						flex: 0 0 14%;
						max-width: 14%;
					}
				}
				&.xl {
					@media only screen and (min-width: $handheld) {
						flex: 0 0 17%;
						max-width: 17%;
					}
				}
				input {
					font-family: $font-primary;
				}
			}
		}
		.card-footer__wrapper {
			width: 100%;
			left: 0;
		}
	}
}
