.user-profile-container {
  margin-right: 30px;
  flex: 1;
  border: 1px solid $color-e8;
  background-color: $color-white;
  label.partner-label {
    margin-bottom: 0px;
  }
  span {
    font-family: $font-primary;
  }
  .details-wrapper {
    padding: 30px;
    border-bottom: 1px solid $color-fe;

    span.title {
      font-size: 17px;
      margin-bottom: 20px;
      display: block;
    }

    .form-group {
      display: flex;
      flex-direction: row;

      .inputs-wrapper {
        select,
        input {
          color: $color-19;
          font-size: 15px;
          border: 1px solid $color-fe;
          border-radius: 4px;
          padding: 9px 15px;
          width: 100%;
          line-height: 20px;

          &:focus {
            outline: none;
          }
        }

        &:not(:last-of-type) {
          margin-right: 20px;
        }
        &[class*='childrenData'] {
          &:not(:last-of-type) {
            margin-right: 16px;
          }
          .react-date-picker__wrapper {
            input.react-date-picker__inputGroup__input {
              width: 24px !important;
              &.react-date-picker__inputGroup__year {
                width: 30px !important;
              }
            }
          }
        }
      }

      .info {
        align-self: center;
        margin-right: 20px;
      }

      .placeholder {
        padding: 10px;
      }

      .arrow {
        align-self: center;
        margin-right: 20px;
      }

      label {
        color: $color-ac;
        font-size: 14px;
        line-height: 28px;
        max-width: 100%;
        .datepicker__calendar {
          line-height: 18px;
        }
      }
    }

    button {
      margin: 0;
    }
  }
}
