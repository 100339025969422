.saveDataType {
  .until-option-radio-and-label {
    margin-top: 10px;
    input[type='radio']:checked ~ .custom-radio {
      &:before {
        content: '';
        height: 14px;
        width: 14px;
        border-radius: 50px;
        background-color: #1a96b4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  span.option-until {
    .custom-radio {
      display: inline-block;
      vertical-align: middle;
    }
    .datepicker__calendar {
      margin-left: 15px;
      display: inline-block;
      width: 15%;
    }
  }
  span[class^='option-'] {
    display: block;
    margin: 25px auto;
  }
  label {
    display: flex;
    cursor: pointer;
    input[type='radio'] {
      display: none;
    }
    .custom-radio {
      height: 24px;
      width: 24px;
      border: 1px solid #e8e8e8;
      border-radius: 50px;
      cursor: pointer;
      margin-bottom: 0;
      display: inline-block;
      position: relative;
      margin-left: 0;
    }
    input[type='radio']:checked ~ .custom-radio {
      &:before {
        content: '';
        height: 14px;
        width: 14px;
        border-radius: 50px;
        background-color: #1a96b4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .custom-radio-label {
    padding-left: 10px;
  }
}

.save-data-form-wrapper {
  background: $color-white;
  border: 1px solid #e4eafe;
  border-radius: 4px;
  margin-bottom: 40px;
  .save-data-form {
    padding: 35px 35px 0px 35px;
    h1 {
      padding: 10px 0 10px 0;
    }
    p {
      padding: 10px 0 20px 0;
    }
  }
}

.next--step {
  margin: 25px 0px 25px 35px;
}

.react-date-picker--disabled {
  input:disabled {
    background-color: #f0f0f0 !important;
  }
}

.calendar.hide-calendar {
  display: none;
}
