.personal-situation {
  font-size: 14px;
  padding-bottom: 5px;
  padding-top: 15px;
  select {
    line-height: 40px;
    height: 40px;
    width: 200px;
  }
}
