.dropdown-menu {
  padding: 25px 30px;
  background: $color-3d;
  right: 0;
  left: auto;
  min-width: 270px;
  top: 18px!important;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 13px solid $color-3d;
    position: absolute;
    top: -13px;
    right: 15px;
  }

  .user-menu__item {
    text-decoration: none;

    a, span {
      font-family: $font-primary;
      font-size: 16px;
      color: $color-white;
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
