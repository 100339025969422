@import 'styles/variables.scss';
.about-wrapper {
  margin-top: 70px;
  margin-bottom: 160px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    font-family: $font-primary;
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
  }

  span {
    font-size: 20px;
    font-weight: 300;
    font-family: $font-primary;
  }

  .info {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;

    &-inner {
      width: 53%;
      font-weight: 300;
      font-family: $font-primary;
      font-size: 18px;
      margin-top: 40px;
      margin-bottom: 80px;

      @media screen and (max-width: $screen-md-min) {
        width: 100%;
      } 
    }
  }

  .team-wrapper {
    background-color: $color-f9;
    padding-bottom: 92px;

    .team-inner {
      max-width: 1200px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }

  .advisory-inner {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .team-wrapper,
  .advisory-wrapper {
    h2 {
      padding-top: 80px;
      font-size: 26px;
      font-weight: bold;
      line-height: 34px;
      font-family: $font-primary;
      margin-bottom: 0;
    }

    > span {
      font-size: 20px;
      font-weight: 200;
      font-family: $font-primary;
      margin-bottom: 40px;
      color: $color-a19;
    }

    .members {
      display: flex;
      flex-direction: row;
      margin-top: 50px;
      flex-wrap: wrap;

      .member {
        width: 120px;
        margin-right: 100px;

        @media screen and (max-width: $screen-md-min) {
          margin-right: 20px;
          margin-bottom: 40px;
        }

        .img-wrapper {
          width: 102px;
          height: 132px;
          border: 1px solid $color-e8;
          border-radius: 4px;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .name-wrapper {
          font-size: 18px;
          font-family: $font-primary;
          font-weight: bold;
          height: 54px;
          margin-top: 13px;
          width: 60%;
        }

        .position {
          font-weight: 300;
          font-size: 16px;
          font-family: $font-primary;
          margin-top: 13px;
          width: 80%;
        }
      }
    }
  }
}
