.edit-user-container {
  h1 {
    font-size: 20px;
    line-height: 25px;
    margin: 35px 0;
  }
  .edit-user-wrapper {
    display: flex;
    span {
      font-family: $font-primary;
      &.title {
        font-size: 17px;
        margin-bottom: 20px;
        display: block;
      }
    }
    .edit-user-details {
      margin-right: 30px;
      flex: 2;
      border: 1px solid $color-e8;
      background-color: $color-white;
      padding: 30px;
      border-bottom: 1px solid $color-fe;
      .btn--secondary {
        padding-top: 38px;
      }
      .form-group {
        display: flex;
        flex-direction: row;
        .inputs-wrapper {
          select,
          input {
            color: $color-19;
            font-size: 15px;
            border: 1px solid $color-fe;
            border-radius: 4px;
            padding: 9px 15px;
            width: 100%;
            line-height: 20px;
            &:focus {
              outline: none;
            }
          }
          &:not(:last-of-type) {
            margin-right: 20px;
          }
        }
        .info {
          align-self: center;
          margin-right: 20px;
        }
        .arrow {
          align-self: center;
          margin-right: 20px;
        }
        label {
          color: $color-ac;
          font-size: 14px;
          line-height: 28px;
          width: 100%;
          span {
            color: $color-19;
            font-size: 15px;
            padding: 0 15px;
          }
        }
        button {
          margin: 0;
        }
      }
      .label {
        color: $color-ac;
        font-size: 14px;
        line-height: 28px;
        width: 100%;
      }
    }
    .edit-user-actions {
      display: flex;
      flex-direction: column;
      flex: 1;
      .action-wrapper {
        background: $color-white;
        padding: 30px 40px 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid $color-e8;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .flex-wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          &.title-button {
            width: 100%;
            span.title {
              margin-bottom: 0;
            }
          }
        }
        .action-content {
          width: 100%;
          border: 1px solid $color-fe;
          .add-remove-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid $color-fe;
            padding: 15px;
            transition: 0.3s ease-out;
            &:last-child {
              border-bottom: none;
            }
            span.title {
              margin-bottom: 0;
            }
            .action-delete {
              border: 1px solid $color-fe;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              cursor: pointer;
              padding: 0 5px;
              transition: 0.3s ease-out;
            }
            &:hover {
              .action-delete {
                border-color: $color-red;
              }
            }
            &.org-row {
              .renderfield {
                input {
                  border: none;
                  font-size: 16px;
                  color: $color-ac;
                }
              }
            }
          }
        }
        .btn {
          margin: 0 10px;
          transition: 0.3s ease-out;
          min-width: 115px;
          position: relative;
          &:first-child {
            margin-left: 0;
          }
          &:not(:disabled):hover {
            opacity: 0.8;
          }
          &.btn--primary {
            background-color: $color-disable;
            color: $color-19;
          }
          &.btn--special {
            background-color: $color-red;
            color: $color-white;
          }
          &.btn--secondary {
            padding: 8px 0px 8px 30px;
            min-width: auto;
            color: $color-b4;
            svg {
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .user-disabled {
          .btn {
            &.btn--primary {
              background-color: $color-94;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.bottom-bar {
  border: 1px solid $color-fe;
  border-radius: 4px;
  background-color: $color-white;
  margin: 30px 0 15px;
  padding: 15px 30px;
  > span:first-of-type {
    padding: 6px 12px;
    border: 1px solid $color-fe;
    border-radius: 4px;
    font-weight: bold;
    margin-right: 5px;
  }
  .btn {
    margin: 0 10px;
    transition: 0.3s ease-out;
    min-width: 115px;
    &:not(:disabled):hover {
      opacity: 0.8;
    }
    &.btn--primary {
      background-color: $color-disable;
      color: $color-19;
    }
    &.btn--special {
      background-color: $color-red;
      color: $color-white;
    }
    &.btn--secondary {
      padding: 8px 0px;
      min-width: auto;
    }
  }
}

.topbar-search .search-by {
  margin-left: 15px;
  select {
    color: $color-19;
    font-size: 15px;
    border: 1px solid $color-fe;
    border-radius: 4px;
    padding: 9px 15px;
    width: 100%;
    line-height: 20px;
    outline: none;
    cursor: pointer;
  }
}

.ReactModalPortal .Overlay .Modal.confirmation.add-new-role-modal {
  border: 1px solid $color-fe;
  padding: 30px;
  span.title {
    font-size: 17px;
    margin-bottom: 20px;
    display: block;
  }
  select {
    color: $color-19;
    font-size: 15px;
    border: 1px solid $color-fe;
    border-radius: 4px;
    padding: 9px 15px;
    width: 100%;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .flex-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.ReactModalPortal .Overlay .Modal.add-user {
  top: 30px;
  font-size: 14px;
  animation: modal-anim-small 0.3s ease-out;
  .add-user-details {
    .top-info {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $color-fe;
      span.title {
        color: $color-ac;
        font-size: 16px;
      }
      h2 {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
    form {
      padding-bottom: 0;
      span.form-error {
        color: $color-red;
        font-size: 12px;
      }
      .content-hide {
        display: none;
      }
      .content-show {
        display: flex;
        margin-top: 15px;
        .renderfield {
          width: 100%;
        }
      }
    }
    background-color: $color-white;
    .form-group {
      display: flex;
      flex-direction: row;

      .inputs-wrapper {
        select,
        input {
          color: $color-19;
          font-size: 15px;
          border: 1px solid $color-fe;
          border-radius: 4px;
          padding: 9px 15px;
          width: 100%;
          line-height: 20px;
          &:focus {
            outline: none;
          }
        }
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
      .info {
        align-self: center;
        margin-right: 20px;
      }
      .arrow {
        align-self: center;
        margin-right: 20px;
      }
      label {
        color: $color-ac;
        font-size: 14px;
        line-height: 28px;
        width: 100%;
        position: relative;
        span:not(.uploadUsersFile) {
          color: $color-19;
          font-size: 15px;
          padding: 0 15px;
          &.form-error {
            color: $color-red;
            font-size: 12px;
          }
        }
        span.uploadUsersFile {
          display: flex;
          align-items: center;
          a {
            display: flex;
            margin-left: 10px;
          }
        }
        span.form-error {
          padding: 0;
        }
        .type-error {
          span {
            color: $color-white;
          }
        }
        input[type='file'] {
          position: absolute;
          bottom: 0;
          opacity: 0;
        }
      }
      button {
        margin: 0;
      }
      &.b-bottom {
        border-bottom: 1px solid $color-fe;
        padding-bottom: 15px;
      }
    }
    span {
      &.label {
        color: $color-ac;
        font-size: 14px;
        line-height: 28px;
        width: 100%;
      }
      &.title {
        font-size: 17px;
      }
      &.d-block {
        display: block;
      }
    }
    .btn--special {
      display: flex;
      align-items: center;
      svg {
        margin-left: 5px;
      }
    }
    .btn-input-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      .inputs-wrapper {
        width: 100%;
      }
      .btn--special {
        color: $color-ac;
      }
    }
  }
}

.users {
  .content-pagination {
    justify-content: space-between;
    padding: 0 30px 30px 30px;
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 20px;
      span {
        font-family: $font-primary;
        font-size: 12px;
      }
      &.active {
        border: 1px solid $color-ac;
        border-radius: 4px;
        background-color: $color-ac;
        span {
          color: $color-white;
        }
      }
      &.disabled {
        border: 1px solid $color-light-grey;
        border-radius: 4px;
        span {
          color: $color-ac;
        }
      }
    }
  }
}

@keyframes modal-anim-small {
  0% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 30px;
    opacity: 1;
  }
}

.ReactModalPortal .Overlay .Modal.confirm-enable-organisation {
  border: none;
  padding: 0;
  top: 30px;
  font-size: 14px;
  animation: modal-anim-small 0.3s ease-out;
  .admin-organisations {
    h2 {
      padding: 30px;
      margin-bottom: 0;
    }
    .admin-lists__topbar {
      padding: 0 30px 30px;
    }
    .admin-lists {
      border: none;
      .disabled {
        opacity: 0.5;
        .action-select {
          cursor: default !important;
          border-color: $color-ac !important;
          color: $color-ac !important;
          pointer-events: none;
        }
      }
      .list__titles {
        .field:last-of-type {
          width: 100px;
          flex: 0 0 100px !important;
          text-align: center !important;
        }
      }
      .field {
        &.actions {
          .action-select {
            width: 100px;
            padding: 0 10px;
            transition: 0.3s ease-out;
            color: $color-94;
            border-color: $color-94;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

// Add multiple user-status-true

.admin-user-details {
  .content-list {
    padding: 30px;
    .list__titles {
      display: flex;
      flex-direction: row;
      padding: 10px 20px;
      .field {
        flex: 0 0 20%;
        position: relative;
        &:last-of-type {
          flex: 0 0 40%;
          padding-right: 0;
          .label {
            padding-right: 0;
          }
        }
        &.empty-admin-field {
          flex: 0 0 3%;
        }
        .label {
          color: $color-ac;
          font-size: 14px;
          font-family: $font-primary;
          padding-right: 15px;
          position: relative;
          display: inline-block;
        }
      }
    }
    .list__rows {
      border: 1px solid $color-fe;
      border-radius: 4px;
      overflow-x: hidden;
      height: 350px;
      .admin-row {
        // height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 20px;
        &:not(:last-of-type) {
          border-bottom: 1px solid $color-fe;
        }
        &:nth-child(2n) {
          background-color: $color-admin;
        }
        .field {
          flex: 0 0 20%;
          &:last-of-type {
            flex: 0 0 40%;
          }

          &.fail {
            color: $color-red;
          }

          &.success {
            color: $color-green;
          }
        }
        &.disabled {
          > div {
            color: $color-ac;
            font-style: italic;
          }
        }
        &.row-disabled {
          .field:not(.checkbox):not(.actions) {
            opacity: 0.6;
            color: $color-ac;
          }
          > svg {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .list__buttons {
    display: flex;
    margin: 15px 0;

    .btn {
      margin-right: 15px;
    }
  }
}

.reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.import-details-title {
  color: $color-ac;
}

.secondary-button {
  border: 1px solid $color-fe;
  background-color: $color-white;
  text-align: center;
  padding: 8px 30px 8px 30px;
}

.align-right{
  display: inherit;
  margin-left: auto;
  .import-list{
    margin-right: 25px;
  }
}
