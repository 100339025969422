// common styles for ADMIN PAGES
.admin-container {
  h1 {
    font-size: 20px;
    line-height: 25px;
    margin: 35px 0;
  }
}

.admin-lists-wrapper {
  .admin-lists {
    border: 1px solid $color-fe;
    border-radius: 4px;
    background-color: $color-white;
    &__topbar {
      padding: 20px 30px;
      background-color: $color-admin;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $color-fe;
      .import-list {
        margin-left: auto;
        border: 1px solid $color-fe;
        background-color: $color-white;
        text-align: center;
        padding: 8px 30px 8px 30px;
      }
      .topbar-addnew {
        margin-left: auto;
        position: relative;
        svg {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          g {
            fill: $color-white;
          }
        }
        button.btn {
          padding: 8px 20px 8px 40px;
        }
        .dropdown-menu {
          min-width: 285px;
          background-color: $color-b4;
          top: 22px !important;
          &:after {
            border-bottom-color: $color-b4;
          }
          .user-menu__item {
            span {
              text-transform: uppercase;
              font-size: 14px;
              line-height: 18px;
              cursor: pointer;
            }
            &:first-child {
              margin-bottom: 15px;
            }
          }
        }
      }
      .topbar-search {
        display: flex;
        margin-left: 25px;
        input {
          border: 1px solid $color-fe;
          height: 40px;
          border-radius: 4px 0 0 4px;
          background-color: $color-white;
          padding: 11px 20px;
          &::placeholder {
            color: $color-ac;
            font-size: 14px;
          }
          &:focus {
            outline: none;
          }
        }
        .search-button {
          border: 1px solid $color-fe;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $color-white;
          width: 45px;
          border-radius: 0 4px 4px 0;
          cursor: pointer;
          margin-left: -1px;
        }
      }
    }
    &__content {
      .content-list {
        padding: 30px;
        .list__titles {
          display: flex;
          flex-direction: row;
          padding: 10px 20px;
          .field {
            flex: 0 0 15%;
            position: relative;
            &.max {
              flex: 0 0 20%;
            }
            &:last-of-type {
              margin-left: auto;
              text-align: right;
              padding-right: 0;
              .label {
                padding-right: 0;
              }
            }
            &.empty-admin-field {
              flex: 0 0 3%;
            }
            .label {
              color: $color-ac;
              font-size: 14px;
              font-family: $font-primary;
              padding-right: 15px;
              position: relative;
              display: inline-block;
              .sort {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .sort-asc {
                  margin-bottom: 3px;
                }
                svg {
                  display: block;
                  cursor: pointer;
                }
              }
            }
          }
          .field.reset-field-css {
            all: unset;
          }

          .field.reset-field-css.status--field {
            margin-left: 6%;
          }
          .field.reset-field-css.birthday--field {
            margin-left: 7%;
          }
          .field.reset-field-css.actions--field {
            margin-left: 7%;
          }
        }
        .list__rows {
          border: 1px solid $color-fe;
          border-radius: 4px;
          .admin-row {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 20px;
            &:not(:last-of-type) {
              border-bottom: 1px solid $color-fe;
            }
            &:nth-child(2n) {
              background-color: $color-admin;
            }
            .field {
              flex: 0 0 15%;
              max-width: 15%;
              text-align: left;
              &.max {
                flex: 0 0 20%;
                max-width: 20%;
              }
              &:last-of-type {
                margin-left: auto;
                text-align: right;
              }
              &.actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                > div:not(.tooltip) {
                  height: 30px;
                  width: 33px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  border-radius: 4px;
                  border: 1px solid $color-fe;
                  margin-left: 10px;
                  &.action-edit {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                      border: 1px solid $color-ac;
                    }
                  }
                  .action-enable {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                    &:hover {
                      border: 1px solid $color-94;
                    }
                  }
                  .action-disable {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    &:hover {
                      border: 1px solid $color-disable;
                    }
                  }
                  &.action-delete {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                      border: 1px solid $color-red;
                    }
                    &.disabled {
                      cursor: default;
                      opacity: 0.2;
                      pointer-events: none;
                      + .delete {
                        display: none;
                        opacity: 0;
                        visibility: hidden;
                      }
                    }
                  }
                }
              }
              &.checkbox {
                flex: 0 0 3%;
              }
			}
			.field.reset-field-css {
				all: unset;
			}
            &.disabled {
              > div {
                color: $color-ac;
                font-style: italic;
              }
            }
            &.row-disabled {
              .field:not(.checkbox):not(.actions) {
                opacity: 0.6;
                color: $color-ac;
              }
              > svg {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
  .content-pagination {
    display: flex;
    justify-content: center;
    .pagination {
      margin-bottom: 30px;
      li {
        height: 30px;
        width: 30px;
        border: 1px solid $color-light-grey;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-out;
        cursor: pointer;
        a {
          font-size: 12px;
          font-weight: 600;
          font-family: $font-primary;
          color: $color-ac;
          text-decoration: none;
          &.pagination-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &:hover {
          box-shadow: 0 0 10px 0 $color-light-grey;
        }
        &.active {
          background-color: $color-ac;
          a {
            color: $color-white;
          }
        }
        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    }
  }

  .content-pagination {
    display: flex;
    justify-content: center;
    .pagination {
      margin-bottom: 30px;
      li {
        height: 30px;
        width: 30px;
        border: 1px solid $color-light-grey;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-out;
        cursor: pointer;
        a {
          font-size: 12px;
          font-weight: 600;
          font-family: $font-primary;
          color: $color-ac;
          text-decoration: none;
          &.pagination-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &.active {
          background-color: $color-ac;

          a {
            color: $color-white;
          }
        }

        &:not(:first-of-type) {
          margin-left: 10px;
        }
      }
    }
  }
}

.admin-container .__react_component_tooltip {
  padding: 12px 17px;
  &.tooltip.edit {
    background-color: $color-ac !important;
    &:after {
      border-bottom-color: $color-ac;
    }
  }
  &.tooltip.disable {
    background-color: $color-disable !important;
    span {
      color: $color-19;
    }
    &:after {
      border-bottom-color: $color-disable;
    }
  }
  &.tooltip.enable {
    background-color: $color-94 !important;
    &:after {
      border-bottom-color: $color-94;
    }
  }
  &.tooltip.delete {
    background-color: $color-red !important;
    &:after {
      border-bottom-color: $color-red;
    }
  }
}

.extend--overflow {
  overflow: 'auto';
}
