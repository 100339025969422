@import 'styles/variables.scss';
.landing-pages-container {
  .footer-container {
    background-color: $color-4b;
    height: auto;
    margin-bottom: 0;
    margin-top: 0;

    @media screen and (max-width: $desktop) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
  .footer-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  .highlighted-text {
    color: $hp-white;
    font-family: $hp-font;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  .contact-list {
    list-style: none;
    padding: 0;
    margin-top: 25px;
    li {
      color: $hp-white;
      font-family: $hp-font;
      font-weight: 300;
      font-size: 18px;
      line-height: 32px;
    }
  }

  .social--media {
    .social--list {
      margin-top: 50%;
      margin-bottom: 50%;
      padding: 0;
      display: flex;

      li {
        padding: 0;
        display: flex;
        &:not(:last-of-type) {
          margin-right: 16px;
        }

        a {
          display: block;
          width: 38px;
          height: 38px;
          background-color: $footer-background;
          background-repeat: no-repeat;
        }
        &.linked-in-icon {
          a {
            background-image: url('../images/social-media/linkedin-icon-white.svg');
            @media screen and (min-width: $screen-md-min) {
              &:hover {
                background-image: url('../images/social-media/linkedin-icon-white-hover.svg');
              }
            }
          }
        }
        &.facebook-icon {
          a {
            background-image: url('../images/social-media/facebook-icon-white.svg');
            @media screen and (min-width: $screen-md-min) {
              &:hover {
                background-image: url('../images/social-media/facebook-icon-white-hover.svg');
              }
            }
          }
        }
        &.twitter-icon {
          a {
            background-image: url('../images/social-media/twitter-icon-white.svg');
            @media screen and (min-width: $screen-md-min) {
              &:hover {
                background-image: url('../images/social-media/twitter-icon-white-hover.svg');
              }
            }
          }
        }
      }
    }
  }

  .sitemap-list {
    list-style: none;
    padding: 0;
    margin-top: 25px;
  }

  .footer-copyright {
    color: $hp-white;
    font-family: $hp-font;
    font-size: 18px;
    font-weight: 200;
    line-height: 32px;
  }

  .footer-standard-text {
    padding-bottom: 27px;

    p {
      color: $color-white;
      font-family: $hp-font;
      font-size: 18px;
      font-weight: 300;
      line-height: 30px;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $screen-md-min) {
    .footer-container {
      padding-top: 33px;
      padding-bottom: 27px;
    }

    .contact-list {
      margin-bottom: 0;

      li {
        line-height: 28px;
      }
    }

    .highlighted-text {
      margin-bottom: 0;
      line-height: 1;
    }

    .footer-standard-text {
      padding-bottom: 35px;
      border-bottom: 1px dashed $hp-separator-color;

      p {
        color: $color-white;
        font-family: $hp-font;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .contact--list {
      padding-bottom: 31px;
      padding-top: 31px;
      border-top: 1px dashed $hp-separator-color;
      border-bottom: 1px dashed $hp-separator-color;
      color: $color-white;
      font-family: $hp-font;
      font-size: 16px;
      line-height: 24px;
    }
    .sitemap--wrapper {
      padding-top: 28px;
      padding-bottom: 30px;
      color: $color-white;
      font-family: $hp-font;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      border-bottom: 1px dashed $hp-separator-color;

      h2 {
        font-weight: 300;
        line-height: 32px;
      }

      ul li a {
        line-height: 32px;
      }
    }

    .social--media {
      .highlighted-text {
        margin-top: 31px;
      }
      .social--list {
        margin-top: 10%;
        margin-bottom: 10%;
        padding: 0;
      }
    }
    .footer-copyright {
      position: relative;
      margin-top: 30px;
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      color: $color-77;
    }
  }
}
