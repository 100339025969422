.admin-reports {
  h1 {
    font-size: 20px;
    line-height: 25px;
    margin: 35px 0;
  }

  .reports-list {
    border: 1px solid $color-fe;
    border-radius: 4px;
    background-color: $color-white;
    padding: 30px;

    .list__titles {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;

      span {
        font-family: $font-primary;
        color: $color-ac;
        font-size: 14px;
      }
    }

    .list__rows {
      border: 1px solid $color-fe;
      border-radius: 4px;
      margin-top: 10px;

      .report-row {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 20px;
        justify-content: space-between;

        &:not(:last-of-type) {
          border-bottom: 1px solid $color-fe;
        }

        &:nth-child(2n) {
          background-color: $color-admin;
        }

        .action-download {
          height: 30px;
          width: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 4px;
          border: 1px solid $color-fe;

          &:hover {
            border: 1px solid $color-94;
          }
        }

        .action-download.active {
          background-color: $color-white;
          &:hover {
            border: 1px solid $color-94;
          }
        }

        .action-download.inactive {
          pointer-events: none;
        }

        .__react_component_tooltip {
          padding: 12px 17px;
          &.tooltip.download {
            background-color: $color-94 !important;
            &:after {
              border-bottom-color: $color-94;
            }
          }
        }

        .endorser--report-field {
          display: inline-flex;
          .top-organizations-selector {
            margin-right: 25px;
          }
          select {
            height: 30px;
            color: $color-a19;
            font-size: 15px;
            border: 1px solid $color-white;
            border-radius: 4px;
            width: 100%;
            padding: 5px 15px 5px 15px;
          }
        }
      }
    }

    .list__rows:first-child {
      margin-top: none;
    }
  }
}
