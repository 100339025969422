.card-footer__wrapper {
  background: $color-white;
  padding: 10px 40px;
  // margin: 40px -40px 0 -40px;
  position:absolute;
  bottom:0;
  width:calc(100% + 80px);
  left:-40px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card-footer__wrapper {
    position: relative;
  }
}
