.privacy-container {
  margin: 70px 0 160px 0;
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    line-height: 36px;
    font-family: $font-primary;
  }

  .privacy-content {
    .subtitle {
      font-family: $font-primary;
      font-size: 20px;
      margin: 30px 0 20px 0;
    }

    ul {
      list-style: none;

      li {
        font-size: 18px;
        font-weight: 300;
        font-family: $font-primary;
        margin-bottom: 10px;
      }
    }
  }
}
