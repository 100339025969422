.faq-wrapper {
  margin: 70px 0;

  h1 {
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    font-family: $font-primary;
    margin-bottom: 40px;
  }

  span.question {
    display: block;
    cursor: pointer;
    font-family: $font-primary;
    font-size: 22px;
    font-weight: bold;
    padding: 20px 0 20px 30px;
    position: relative;

    &:before {
      // content: "\&#10095;";
      content:">";
      display: block;
      position: absolute;
      left: 0;
    }
  }

  .answer-wrapper {
    span, li {
      font-family: $font-primary;
      font-size: 18px;
      font-weight: 300;
    }
    span {
      display: block;
      &.subtitle {
        font-weight: bold;
        margin: 10px 0;
      }
    }
  }
}
