@import 'styles/variables.scss';

.header-container {
  padding: 30px 0;

  .header-wrapper {
    display: flex;
    align-items: center;
    padding: 0;

    @media screen and (max-width: $screen-md-min) {
      padding-left: 0;
    }

    .header__navigation {
      display: none;

      @media screen and (min-width: $screen-md-min) {
        display: block;
        flex: 1;
        .navbar {
          padding: 0;

          .navbar-nav {
            flex-direction: row;

            .nav-item {
              a {
                color: $color-a19;
                font-size: 14px;
                text-transform: uppercase;
                font-family: $font-primary;

                &:hover {
                  text-decoration: none;
                }
              }

              &:not(:last-of-type) {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }

    .header__login {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .navigation__lang-switch {
        .active {
          .icon-wrapper {
            background-color: $color-e8;
          }
        }
      }
    }
  }
}

// Responsive section
