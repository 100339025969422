.enter-email-wrapper {
  margin-top: 70px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    line-height: 36px;
    font-family: $font-primary;
  }

  span {
    font-size: 20px;
    font-weight: 300;
    font-family: $font-primary;
  }

  div.error {
    color: $color-89;
  }

  .enter-email-form-wrapper {
    display: flex;
    margin-bottom: 160px;

    #enter-email-password-form {
      flex: 1;
      margin-right: 130px;
      margin-top: 40px;

      .label-name {
        display: inline-block;
        background: white;
        width:auto;
        padding: 0 10px;
        font-size: 14px;
        color: $color-77;
        position: relative;
        top: 10px;
        left: 12px;
        height: 22px;
      }

      .label-error {
        display: inline-block;
        background: white;
        width:auto;
        padding: 0 10px;
        font-size: 14px;
        color: $color-89;
        position: relative;
        top: 10px;
        right: -30%;
      }

      .form-group {
        label,
        .FormInput {
          width: 100%;
        }

        label {
          input {
            border: 1px solid $color-e8;
            border-radius: 4px;
            padding: 12px 20px;
            font-size: 18px;
            font-family: $font-primary;
            width: 40%;
            background-color: $color-white;
          }

          input:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px white inset;
          }
          input {
            &:focus {
              outline: none;
            }

            &::placeholder {
              font-size: 18px;
            }
          }

          &.error {
            input {
              border: 1px solid $color-89;
            }
          }
        }
      }
    }

     .span-btn {
      display: block;
      font-size: 18px;
      font-weight: 300;
      font-family: $font-primary;
      cursor: pointer;
      width: 150px;
    }
  }
}
