.login-wrapper {
  margin-top: 70px;

  h1 {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    line-height: 36px;
    font-family: $font-primary;
  }

  span {
    font-size: 20px;
    font-weight: 300;
    font-family: $font-primary;
  }

  .error {
    color: $color-89;
    font-size: 16px;
  }

  .login-form-wrapper {
    display: flex;
    margin-bottom: 160px;

    #login-form {
      flex: 1;
      margin-right: 130px;
      margin-top: 40px;

      .label-name {
        display: inline-block;
        background: white;
        width: auto;
        padding: 0 10px;
        font-size: 14px;
        color: $color-77;
        position: relative;
        top: 10px;
        left: 12px;
        height: 22px;
      }

      .form-group {
        label,
        .FormInput {
          width: 400px;
          height: 55px;
        }

        label {
          input {
            border: 1px solid $color-e8;
            border-radius: 4px;
            padding: 12px 20px;
            font-size: 18px;
            font-family: $font-primary;
            width: 100%;
            background-color: $color-white;
          }
          span {
            display: inline-block;
            width: auto;
            padding: 0 10px;
            font-size: 14px;
          }

          input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset;
          }
          input {
            &:focus {
              outline: none;
            }

            &::placeholder {
              font-size: 18px;
            }
            &.error {
              border: 1px solid $color-red;
            }
          }
        }
      }
      .btn {
        display: block;
      }

      span {
        display: inline-block;
        font-size: 18px;
        font-weight: 300;
        cursor: pointer;
      }
    }
  }
}
