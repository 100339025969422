.card-header__wrapper {
  margin-top: 40px;

  .card-header__topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      align-items: center;

      span:first-of-type {
        font-size: 20px;
      }

      span.info-icon{
        padding-left: 10px;
      }

    }

    .current-year {
      display: flex;
      align-items: center;

      .yearpicker {
        display: flex;
        border: 1px solid $color-fe;
        border-radius: 4px;
        background-color: $color-white;
        color: $color-77;
        align-items: center;
        margin-left: 15px;

        .yearpicker__prev {
          padding: 4px 10px 2px 10px;
          border-right: 1px solid $color-fe;
          cursor: pointer;
          &.disabled {
            opacity:0.3;
            cursor: not-allowed;
          }
        }

        .yearpicker__selected-year {
          font-size: 16px;
          padding: 0 15px;
        }

        .yearpicker__next {
          padding: 4px 10px 2px 10px;
          border-left: 1px solid $color-fe;
          cursor: pointer;
          &.disabled {
            opacity:0.3;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .card-header__content {
    display: flex;
    margin-top: 20px;

    .age {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid $color-fe;
    }

    .line {
      span:nth-of-type(2) {
        font-weight: bold;
      }
    }
  }

  .label {
    color: $color-ac;
    font-size: 14px;
  }
}
