.loader {
  padding: 20px 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disposable-chart{
  .disposable-chart__label {
    font-size: 16px;
    padding: 26px 0px 20px 30px;
  }
  .bar-chart__chart {
    padding-bottom: 20px;
  }
  text {
      font: 13px SourceSansPro;
      fill: $color-ac;
  }
  .recharts-layer {
    cursor: pointer;
  }
}
