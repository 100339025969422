@font-face {
  font-family: SourceSansPro;
  src: url('./fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SourceSansPro;
  src: url('./fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Roboto Slab;
  src: url('./fonts/Roboto-Slab/RobotoSlab-Regular.ttf') format('truetype');
  font-style: normal;
}
